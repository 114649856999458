import React, { useState, Fragment } from "react";
import useGuestData from "@/hooks/useGuestData";

const PERSONAL = 1;
const ALL = 2;
const urlcode =
  "tes%20kirim%20isian%20buat%20anda%0A%0Adiharap%20mengisikan%20konfirmasi%20kehadiran";
function GenerateLink() {
  const [type, setType] = useState(PERSONAL);
  const [name, setName] = useState();
  const [showResult, setShowResult] = useState(false);
  const [successCopy, setSuccessCopy] = useState(false);
  const [isInvitation, setIsInvitation] = useState(false);

  const { data, loading } = useGuestData();

  const URL = `https://januindra.futurewedding.my.id?to=${encodeURIComponent(
    name
  )}`;

  const URLCOPY = `
  
  السَّلاَمُ عَلَيْكُمْ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ
  بِسْــــــــــــــــــمِ اللهِ الرَّحْمَنِ الرَّحِيْمِ

Dear *${name}*

Semoga *${name}* dalam keadaan sehat dan selalu dalam lindunganNya  
Alhamdulillah, pada akhirnya kami bisa menyampaikan sebuah kabar bahagia yaitu: 

*PERNIKAHAN JANU & INDRA* ️🧕🤵‍

Untuk informasi detail acara mohon klik link di bawah :
*${URL}*

Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan untuk hadir dan memberikan doa restu.

_*Serta tanpa mengurangi rasa hormat, kami memohon untuk konfirmasi kehadiran dengan membalas Ya/Tidak pada undangan digital dari kami atau mereply pesan ini ya, Terima Kasih❤️._*

Kami yang berbahagia
*Wahyu Januari & Imanindra Permana*

اَلْحَمْدُ للَّهِ جَزَاكُمُ اللهُ خَيْرًا
وَالسَّلاَمُ عَلَيْكُمْ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ`;
  const handleChange = (e) => {
    setType(parseInt(e.target.value, 10));
    setShowResult(false);
  };

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const handleCopy = async (text, showAlert = false) => {
    try {
      await navigator.clipboard.writeText(text);
      setSuccessCopy(true);
      showAlert && alert("Berhasil");
    } catch (err) {
      setSuccessCopy(false);
      alert("Failed to copy! :(");
    }
  };

  const handleshare = async (text, showAlert = false) => {
    try {
      await navigator.clipboard.writeText(text);
      setSuccessCopy(true);
      showAlert && alert("Berhasil");
    } catch (err) {
      setSuccessCopy(false);
      alert("Failed to copy! :(");
    }
  };

  const renderContentType = () => {
    if (type === PERSONAL) {
      return (
        <Fragment>
          <div class="form-group">
            <label for="exampleInputEmail1">Nama Tamu</label>
            <input
              value={name}
              onChange={handleSetName}
              type="text"
              class="form-control"
              placeholder="Nama tamu.."
            ></input>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            onClick={() => setShowResult(true)}
          >
            Generate Link
          </button>
        </Fragment>
      );
    }

    if (type === ALL) {
      return (
        <Fragment>
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                checked={isInvitation}
                onClick={() => setIsInvitation(!isInvitation)}
              />{" "}
              Tipe Invitation (Datang offline)
            </label>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            onClick={() => setShowResult(true)}
          >
            Generate Link
          </button>
        </Fragment>
      );
    }
  };

  const renderResult = () => {
    if (!showResult) return null;

    if (type === PERSONAL) {
      return (
        <div className="col-md-4 col-md-offset-4">
          <div
            class="alert alert-success"
            role="alert"
            style={{ marginTop: "20px" }}
          >
            <strong>Berhasil!</strong> <br />
            <a
              href={URL}
              target="_blank"
              rel="noreferrer"
              style={{ color: "green", textDecoration: "underline" }}
            >
              {URL}
            </a>
            <button
              type="button"
              className="btn btn-default btn-xs"
              style={{ marginLeft: "8px" }}
              onClick={() => handleCopy(URLCOPY)}
            >
              {successCopy ? "Tersalin" : "Salin"}
            </button>
          </div>
        </div>
      );
    }

    if (type === ALL) {
      return (
        <div
          className="col-md-10 col-md-offset-1"
          style={{ marginTop: "28px" }}
        >
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama</th>
                  <th>Keterangan</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d, index) => {
                  const offlineInvitation = isInvitation
                    ? `&code=${d.code}`
                    : "";
                  const mapURL = `https://januindra.futurewedding.my.id?to=${encodeURIComponent(
                    d.name
                  )}${offlineInvitation}`;
                  const shareWA1 = `https://web.whatsapp.com/send?text=Assalamu%27alaikum%20Warahmatullah%20Wabarakatuh%0ABismillahirahmanirrahim.%0A%0ADear%20${d.name}0A%0ADengan%20memohon%20Rahmat%20%26%20Ridha%20Allah%20SWT%20yang%20telah%20menciptakan%20makhluk-Nya%20berpasang-pasangan.%20Tanpa%20mengurangi%20rasa%20hormat%2C%20perkenankan%20kami%20mengundang%20Bapak%2FIbu%2FSaudara%2Fi%2C%20teman%20sekaligus%20sahabat%2C%20untuk%20menghadiri%20acara%20pernikahan%20kami%20%3A%0A%0A${mapURL}`;
                  const shareWA = `
السَّلاَمُ عَلَيْكُمْ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ
بِسْــــــــــــــــــمِ اللهِ الرَّحْمَنِ الرَّحِيْمِ
                
Dear *${d.name}*
                
Semoga *${d.name}* dalam keadaan sehat dan selalu dalam lindunganNya  
Alhamdulillah, pada akhirnya kami bisa menyampaikan sebuah kabar bahagia yaitu: 
                
*PERNIKAHAN JANU & INDRA* ️🧕🤵‍

Untuk informasi detail acara mohon klik link di bawah :
*${mapURL}*
                
Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan untuk hadir dan memberikan doa restu.
                
*_Serta tanpa mengurangi rasa hormat, kami memohon untuk konfirmasi kehadiran dengan membalas Ya/Tidak pada undangan digital dari kami atau mereply pesan ini ya, Terima Kasih❤️._*
                
Kami yang berbahagia
*Wahyu Januari & Imanindra Permana*
                
اَلْحَمْدُ للَّهِ جَزَاكُمُ اللهُ خَيْرًا
وَالسَّلاَمُ عَلَيْكُمْ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ`;

                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{d.name}</td>
                      <td>{d.desc}</td>
                      <td>
                        <a
                          href={mapURL}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "underline" }}
                        >
                          {mapURL}
                        </a>
                        <button
                          className="btn btn-default btn-sm"
                          style={{
                            fontSize: "12px",
                            padding: "4px 8px",
                            marginLeft: "4px",
                          }}
                          onClick={() => handleCopy(shareWA, true)}
                        >
                          copy
                        </button>

                        <button
                          className="btn btn-default btn-sm"
                          style={{
                            fontSize: "12px",
                            padding: "4px 8px",
                            marginLeft: "4px",
                          }}
                          onClick={() => handleCopy(shareWA, true)}
                          //Hello, world!</a>
                        >
                          Copy Chat Full
                        </button>
                        {/* <a href={shareWA}>Share this</a> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <h2 className="title">Generator of Link Invitation</h2>
      <h3 className="title__sub">Janu & Indra Wedding</h3>

      {loading && <h4 style={{ textAlign: "center" }}>Memuat data..</h4>}

      {!loading && (
        <Fragment>
          <div className="row">
            <div className="col-md-4 col-md-offset-4">
              <div class="form-group">
                <label for="exampleInputEmail1">Tipe Link</label>
                <select
                  class="form-control"
                  value={type}
                  onChange={handleChange}
                >
                  <option value={PERSONAL}>Individu</option>
                  <option value={ALL}>Semua Undangan</option>
                </select>
              </div>
              {renderContentType()}
            </div>
          </div>
          <div className="row">{renderResult()}</div>
        </Fragment>
      )}
    </div>
  );
}

export default GenerateLink;
